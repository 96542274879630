import React from 'react';
import ReactDOM from 'react-dom/client';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import './index.css';
import reportWebVitals from './reportWebVitals';

import Root from './routes/Root';
import Home from './routes/Home';
import About from './routes/About';
import Projects from './routes/Projects';
import CompetitiveProgramming from './routes/interests/CompetitiveProgramming';
import Robotics from './routes/interests/Robotics';
import Speedcubing from './routes/interests/Speedcubing';
import ErrorPage from './routes/ErrorPage';
import { BlogPost, BlogHome, BlogLoader } from './routes/Blog';
import Resume from './routes/Resume';

const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    errorElement: <ErrorPage />,
    // TODO: Figure out why the errorpage is not rendering in outlet
    children: [
      {
        errorElement: <ErrorPage />,
        children: [
          {
            index: true,
            element: <Home />
          },
          {
            path: "about",
            element: <About />,
          },
          {
            path: "projects",
            element: <Projects />,
          },
          {
            path: "blog",
            element: <BlogHome />,
          },
          {
            path: "blog/:name",
            element: <BlogPost />,
            loader: BlogLoader,
          },
          {
            path: "interests/competitive-programming",
            element: <CompetitiveProgramming />,
          },
          {
            path: "interests/robotics",
            element: <Robotics />,
          },
          {
            path: "interests/speedcubing",
            element: <Speedcubing />,
          },
        ],
      },
    ],
  },
  {
    path: "resume",
    element: <Resume />,
    errorElement: <ErrorPage />,
  },
])

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
