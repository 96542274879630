import React, { useEffect, useState } from 'react';
import { useLoaderData, useNavigate } from 'react-router-dom';
import { postData } from '../posts/posts';
import { markdownComponents } from '../posts/markdown-components';

import Markdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import remarkMath from 'remark-math';
import rehypeHighlight from 'rehype-highlight';
import rehypeKatex from 'rehype-katex';
import rehypeRaw from 'rehype-raw';
import remarkRehype from 'remark-rehype';
import 'highlight.js/styles/github-dark.css';

export function BlogLoader({ params }) {
	const name = params.name;
	return { name };
}

export function BlogHome() {
	const [posts, setPosts] = useState([]);

	const getPosts = async () => {
		const promises = postData.map(async (post) => {
			post.link = post.title.toLowerCase().replaceAll(/[^0-9a-z ]/gi, "").replaceAll(" ", "-");
			return await fetch(require("../posts/" + post.file)).then((response) => response.text()).then((text) => {
				post.content = text;
				return post;
			});
		});
		const res = await Promise.all(promises);
		setPosts(res);
	}

	useEffect(() => {
		getPosts();
	}, []);

	return (
		<div className="w-full">
			<h1 className="text-5xl font-bold text-center py-5">ngmh's Blog</h1>
			<div className="w-full flex flex-col items-center space-y-5 py-5">
				{
					posts.map(post => {
						return (
							<div className="w-3/4">
								<a href={"/blog/" + post.link} className="text-3xl font-bold underline">{post.title}</a>
								<p className="text-xl font-bold">ngmh | {post.date}</p>
							</div>
						);
					})
				}
			</div>
		</div>
	)
}

export function BlogPost() {
	const { name } = useLoaderData();
	const [post, setPost] = useState([]);
	const navigate = useNavigate();

	const getPost = async () => {
		const found = postData.find((post) => { return post.title.toLowerCase().replaceAll(/[^0-9a-z ]/gi, "").replaceAll(" ", "-") === name });
		if (typeof found === 'undefined') {
			navigate("/blog", { replace: true });
		} else {
			found.link = found.title.toLowerCase().replaceAll(/[^0-9a-z ]/gi, "").replaceAll(" ", "-");
			await fetch(require("../posts/" + found.file)).then((response) => response.text()).then((text) => {
				found.content = text;
				return found;
			});
			setPost(found);
		}
	}

	useEffect(() => {
		getPost();
	}, []);

	return (
		<div className="w-full">
			<h1 className="text-5xl font-bold text-center pt-5">{post.title}</h1>
			<h1 className="text-xl font-bold text-center">ngmh | {post.date}</h1>
			<div className="w-full flex flex-col items-center space-y-5 py-5">
				<div className="w-3/4 flex flex-col space-y-5">
					<Markdown
						components={markdownComponents}
						remarkPlugins={[remarkGfm, remarkMath, remarkRehype]}
						rehypePlugins={[rehypeHighlight, [rehypeKatex, { output: "mathml" }], rehypeRaw]}
					>
						{post.content}
					</Markdown>
				</div>
			</div>
		</div>
	)
}