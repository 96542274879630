export const postData = [
	{
		id: 0,
		title: "Test Post",
		date: "08/07/2024",
		file: "post1.md",
		tags: ["testing", "post"]
	},
	{
		id: 1,
		title: "Another's Test! Post",
		date: "09/07/2024",
		file: "post2.md",
		tags: ["post"]
	},
]