import React from 'react';

export default function About() {
	return (
		<div className="w-full">
			<div className="hero min-h-screen w-full">
				<div className="hero-content flex-col lg:flex-row lg:space-x-20 text-left">
					<div className="w-3/4">
						<h1 className="text-5xl font-bold text-center">Ng Mun Hin</h1>
						<p className="py-6">
							Hi. I’m Mun Hin, a Full-Stack Developer specialising in web and app development.
							With a background in both front-end and back-end development, I am able to turn ideas into reality.
						</p>
						<p className="py-6">
							I am currently studying Computer Science at the National University of Singapore,
							but have been coding since I was in Secondary school.
							My friend first introduced me to building simple websites in raw HTML / CSS / JS,
							but I have tried many other things since then.
						</p>
						<p className="py-6">
							When I am not working on projects, I enjoy speedcubing or competitive programming.
							I enjoy the mental challenge that they offer, and the sense of accomplishment when breaking new milestones.
						</p>
						<p className="py-6">
							I'm open to working on projects or discussing ideas, so feel free to reach me at <a className="font-bold" href="mailto:ngmh@ngmunhin.com">ngmh@ngmunhin.com</a>.
						</p>
					</div>
				</div>
			</div>
		</div >
	)
}