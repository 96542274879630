import React from 'react';
import { Outlet } from 'react-router-dom';
import Navbar from '../components/Navbar';
import Footer from '../components/Footer';

export default function Root() {
	return (
		<>
			<Navbar />
			<div id="content" className="text-white bg-gray-800 body-font">
				<Outlet />
			</div>
			<Footer />
		</>
	)
}

