import React from 'react';

export default function Navbar() {
	return (
		<div className="navbar bg-base-100 sticky top-0 z-50">
			<div className="navbar-start">
				<div className="dropdown">
					<div tabindex="0" role="button" className="btn btn-ghost md:hidden">
						<svg
							xmlns="http://www.w3.org/2000/svg"
							className="h-5 w-5"
							fill="none"
							viewBox="0 0 24 24"
							stroke="currentColor">
							<path
								stroke-linecap="round"
								stroke-linejoin="round"
								stroke-width="2"
								d="M4 6h16M4 12h8m-8 6h16" />
						</svg>
					</div>
					<ul
						tabindex="0"
						className="menu menu-sm dropdown-content bg-base-100 rounded-box z-[1] mt-3 w-52 p-2 shadow">
						<li>
							<a href="/about" className="hover:text-white">
								About
							</a>
						</li>
						<li>
							<a href="/projects" className="hover:text-white">
								Projects
							</a>
						</li>
						<li>
							<a href="/blog" className="hover:text-white">
								Blog
							</a>
						</li>
						<li>
							<a className="hover:text-white">Interests</a>
							<ul className="p-2">
								<li><a href="/interests/competitive-programming" className="hover:text-white">Competitive Programming</a></li>
								<li><a href="/interests/robotics" className="hover:text-white">Robotics</a></li>
								<li><a href="/interests/speedcubing" className="hover:text-white">Speedcubing</a></li>
							</ul>
						</li>
					</ul>
				</div>
				<a href="/" className="btn btn-ghost text-xl hover:text-white">ngmh</a>
			</div>
			<div className="navbar-center hidden md:flex">
				<ul className="menu menu-horizontal px-1">
					<li>
						<a href="/about" className="hover:text-white">
							About
						</a>
					</li>
					<li>
						<a href="/projects" className="hover:text-white">
							Projects
						</a>
					</li>
					<li>
						<a href="/blog" className="hover:text-white">
							Blog
						</a>
					</li>
					<li>
						<details>
							<summary className="hover:text-white">Interests</summary>
							<ul className="p-2">
								<li><a href="/interests/competitive-programming" className="hover:text-white">Competitive Programming</a></li>
								<li><a href="/interests/robotics" className="hover:text-white">Robotics</a></li>
								<li><a href="/interests/speedcubing" className="hover:text-white">Speedcubing</a></li>
							</ul>
						</details>
					</li>
				</ul>
			</div>
			<div className="navbar-end">

				<a className="btn" href="mailto:ngmh@ngmunhin.com">
					<svg xmlns="http://www.w3.org/2000/svg" width={24} height={24} viewBox="0 0 24 24">
						<path fill="currentColor" d="M20 4H4c-1.1 0-1.99.9-1.99 2L2 18c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V6c0-1.1-.9-2-2-2m0 4l-8 5l-8-5V6l8 5l8-5z"></path>
					</svg>
				</a>
			</div>
		</div>
	);
}