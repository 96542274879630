import React from 'react';

export default function Resume() {
	return (
		// TODO: Get rid of the hash at the end of the file name
		<div class="embed-responsive" style={{ height: "100vh" }}>
			<embed
				src={require("../assets/ng_mun_hin_resume.pdf")}
				type="application/pdf"
				width="100%"
				height="100%"
			/>
		</div>
	)
}