export const markdownComponents = {
	h1(props) {
		const { node, ...rest } = props
		return <h1 className="text-5xl" {...rest} />
	},
	h2(props) {
		const { node, ...rest } = props
		return <h1 className="text-3xl" {...rest} />
	},
	h3(props) {
		const { node, ...rest } = props
		return <h1 className="text-xl" {...rest} />
	},
	ol(props) {
		const { node, ...rest } = props
		return <ol className="list-decimal" {...rest} />
	},
	ul(props) {
		const { node, ...rest } = props
		return <ol className="list-disc" {...rest} />
	},
	a(props) {
		const { node, ...rest } = props
		return <a className="underline" {...rest} />
	},
	table(props) {
		const { node, ...rest } = props
		return <table className="table-auto border-collapse border border-slate-400 dark:border-slate-500 bg-white dark:bg-slate-800 text-sm shadow-sm" {...rest} />
	},
	th(props) {
		const { node, ...rest } = props
		return <th className="border-b dark:border-slate-400 font-medium p-4 pl-8 pt-0 pb-3 text-slate-400 dark:text-slate-200 text-left" {...rest} />
	},
	tbody(props) {
		const { node, ...rest } = props
		return <tbody className="bg-white dark:bg-slate-800" {...rest} />
	},
	td(props) {
		const { node, ...rest } = props
		return <td className="border-b border-slate-100 dark:border-slate-400 p-4 text-slate-500 dark:text-slate-400" {...rest} />
	}
}