import React from 'react';

export default function Robotics() {
	return (
		<div className="w-full">
			<div className="hero min-h-screen w-full">
				<div className="hero-content flex-col lg:flex-row lg:space-x-20 text-left">
					<div className="w-3/4 flex flex-col items-center">
						<h1 className="text-5xl font-bold text-center">Robotics</h1>
						<p className="py-6">
							I was dragged into Robotics in 2019, and continued to do it until
							I graduated in 2021. This is a short rundown of my experience, which
							I felt deserved a page on its own.
						</p>
						<h1 className="text-3xl font-bold">Team Raffles 2019</h1>
						<img
							src={require("../../assets/robotics/2019_nats.JPG")}
							alt="2019 nationals robot"
							className="max-w-lg align-center my-2" />
						<p className="py-6">
							We started from scratch in RoboCup Junior Soccer Lighweight. The most
							experience I had beforehand was a bit of messing around with an Arduino
							during Year 1 CS Lessons, or using a Raspberry Pi as a weather station in Year 2.
							We got <span className="line-through">fairly</span> working robots up in just 3 months, and managed to emerge 1st at Nationals.
							In the process, we also won Best Technical Challenges <span className="line-through">as nobody else participated</span>.
							This gave us the chance to represent Singapore at RoboCup Internationals, held in Sydney.
							Below are the recordings of the matches at Nationals, which weren't exactly of very good quality.
						</p>
						<iframe className="my-2" width="560" height="315" src="https://www.youtube.com/embed/videoseries?si=AX6IsMuS1UDRANk6&amp;list=PLN1sxlQkn96IKDpzxoFgl_SF33oesmi8T" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
						<img
							src={require("../../assets/robotics/2019_intls.jpg")}
							alt="2019 nationals robot"
							className="max-w-lg align-center my-2" />
						<p className="py-6">
							Internationals was a completely new experience for us. The matches
							were tough, and the days were long. Although there were some mistakes made
							and friction with the rules, we came in overall 5th. Superteam
							was also a fun experience, although our robot was trolling most of the
							time as the compass went haywire on the huge field. M&A carried us to
							Superteam World Champions, so there's that. The match videos can be found below.
						</p>
						<iframe className="my-2" width="560" height="315" src="https://www.youtube.com/embed/videoseries?si=AFU8MLU17r6U2ZEJ&amp;list=PLB99XSjoGiG4OU02qk1ha4PWDTR0cBNAY" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
						<img
							src={require("../../assets/robotics/2019_trophies.jpg")}
							alt="2019 nationals robot"
							className="max-w-lg align-center my-2" />
						<p className="py-6">
							Our glorious trophies, the last I checked, are still in a cabinet in the Robolab.
						</p>
						<h1 className="text-3xl font-bold">Team Raffles 2020</h1>
						<p className="py-6">
							At the end of 2019, we started work on our new robots as we would
							be doing the Soccer Open category instead. We did get our robots working,
							but the presence of COVID meant that we were never really able to put
							them to the test in competition. I don't really have any photos of the full robot sadly.
							We went on a very long hiatus until the next year anyway.
						</p>
						<h1 className="text-3xl font-bold">Team Raffles 2021</h1>
						<img
							src={require("../../assets/robotics/2021_robots.jpeg")}
							alt="2019 nationals robot"
							className="max-w-lg align-center my-2" />
						<p className="py-6">
							We once again rebuilt our robots in 2021, in hopes that competition would resume.
							Although there was no physical competition, there was virtual Nationals, where we
							were once again selected to represent Singapore at RoboCup 2021, held virtually.
							The first builds are above, and we eventually added shrouds to make it look pretty
							and prevent interference.
						</p>
						<img
							src={require("../../assets/robotics/2021_robot.jpg")}
							alt="2019 nationals robot"
							className="max-w-lg align-center my-2" />
						<p className="py-6">
							RoboCup 2021 was a different experience from 2019, as it was task based instead of
							having teams face each other. We managed to come in overall second, just behind our
							Superteam partners, Leopard. Superteam was also tasked based, and we did the
							easy task (lol) since our localisation was pretty good on an empty field. Pairing
							it with a fancy bluetooth visualisation got us a shoutout as well from the committee.
							Since it was virtual there was A LOT of presenting, our very nice video series is below.
						</p>
						<iframe className="my-2" width="560" height="315" src="https://www.youtube.com/embed/videoseries?si=6QzqFiEIhmCULZAE&amp;list=PLN1sxlQkn96LSQ8CNWoLaYLfOhmrBqs83" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
						<h1 className="text-3xl font-bold">Closing Thoughts</h1>
						<p className="py-6">
							Although I mostly handled software, it was a completely different ball game from any
							other forms of programming like web development. There were many external factors, such
							as lighting conditions, having to constantly monitor the battery level, and making sure
							nothing fried (spoiler: things did fry). It did teach me a lot of lessons, like DON'T
							TOUCH THE CODE 5 MINUTES BEFORE A MATCH. The grind was absolutely insane, but the results
							were a testament to the effort we put in. Would not recommend to the general public.
						</p>
					</div>
				</div>
			</div>
		</div >
	)
}