import React from 'react';

export default function Projects() {
	return (
		<div className="w-full justify-center flex">
			<div className="w-3/4">
				<div className="hero min-h-screen w-full">
					<div className="hero-content w-full flex flex-col md:flex-row md:justify-evenly text-left">
						<div className="mockup-phone p-1 m-0">
							<div className="camera"></div>
							<div className="display">
								<img
									className="h-full"
									src={require("../assets/edenhome.png")}
									alt="eden" />
							</div>
						</div>
						<div className="max-w-96">
							<h1 className="text-5xl font-bold">Eden - Carbon Tracking, Gamified</h1>
							<p className="py-6">
								Most people are not aware of the carbon footprint that their daily actions have.
								Eden aims to provide everyone with the tools to do so, by tracking factors such as travel, meals, and utility consumption.
							</p>
							<a role="button" className="btn bg-base-100 text-white" href="https://github.com/ngmhprogramming/eden">
								<svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} viewBox="0 0 24 24">
									<path fill="currentColor" d="M12 2A10 10 0 0 0 2 12c0 4.42 2.87 8.17 6.84 9.5c.5.08.66-.23.66-.5v-1.69c-2.77.6-3.36-1.34-3.36-1.34c-.46-1.16-1.11-1.47-1.11-1.47c-.91-.62.07-.6.07-.6c1 .07 1.53 1.03 1.53 1.03c.87 1.52 2.34 1.07 2.91.83c.09-.65.35-1.09.63-1.34c-2.22-.25-4.55-1.11-4.55-4.92c0-1.11.38-2 1.03-2.71c-.1-.25-.45-1.29.1-2.64c0 0 .84-.27 2.75 1.02c.79-.22 1.65-.33 2.5-.33s1.71.11 2.5.33c1.91-1.29 2.75-1.02 2.75-1.02c.55 1.35.2 2.39.1 2.64c.65.71 1.03 1.6 1.03 2.71c0 3.82-2.34 4.66-4.57 4.91c.36.31.69.92.69 1.85V21c0 .27.16.59.67.5C19.14 20.16 22 16.42 22 12A10 10 0 0 0 12 2"></path>
								</svg>
								GitHub
							</a>
						</div>
					</div>
				</div>
				<div className="divider"></div>
				<div className="hero min-h-screen w-full">
					<div className="hero-content w-full flex flex-col md:flex-row md:justify-evenly text-left">
						<div className="max-w-96">
							<h1 className="text-5xl font-bold">ZapQ - Queueing Gone Virtual</h1>
							<p className="py-6">
								During the COVID-19 pandemic, eveyrone was urged to minimise physical contact with others.
								ZapQ aims to aid this goal by preventing the need for users to physically queue up for things such as their favourtie restaurants.
							</p>
							<a role="button" className="btn bg-base-100 text-white" href="https://github.com/ChenNuode/hafFULLhafempty">
								<svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} viewBox="0 0 24 24">
									<path fill="currentColor" d="M12 2A10 10 0 0 0 2 12c0 4.42 2.87 8.17 6.84 9.5c.5.08.66-.23.66-.5v-1.69c-2.77.6-3.36-1.34-3.36-1.34c-.46-1.16-1.11-1.47-1.11-1.47c-.91-.62.07-.6.07-.6c1 .07 1.53 1.03 1.53 1.03c.87 1.52 2.34 1.07 2.91.83c.09-.65.35-1.09.63-1.34c-2.22-.25-4.55-1.11-4.55-4.92c0-1.11.38-2 1.03-2.71c-.1-.25-.45-1.29.1-2.64c0 0 .84-.27 2.75 1.02c.79-.22 1.65-.33 2.5-.33s1.71.11 2.5.33c1.91-1.29 2.75-1.02 2.75-1.02c.55 1.35.2 2.39.1 2.64c.65.71 1.03 1.6 1.03 2.71c0 3.82-2.34 4.66-4.57 4.91c.36.31.69.92.69 1.85V21c0 .27.16.59.67.5C19.14 20.16 22 16.42 22 12A10 10 0 0 0 12 2"></path>
								</svg>
								GitHub
							</a>
						</div>
						<div className="mockup-phone p-1 m-0">
							<div className="camera"></div>
							<div className="display">
								<img
									className="h-full"
									src={require("../assets/zapq.jpg")}
									alt="zapq" />
							</div>
						</div>
					</div>
				</div>
				<div className="divider"></div>
				<div className="hero min-h-screen w-full">
					<div className="hero-content w-full flex flex-col md:flex-row md:justify-evenly text-left">
						<div className="mockup-browser bg-base-300 border max-w-96 m-0">
							<div className="mockup-browser-toolbar">
								<div className="input">https://projectunplug.co</div>
							</div>
							<div className="bg-base-200 flex justify-center">
								<img
									className="w-full"
									src={require("../assets/unplug.png")}
									alt="unplug" />
							</div>
						</div>
						<div className="max-w-96">
							<h1 className="text-5xl font-bold">Unplug - Mental Wellness for Everyone</h1>
							<p className="py-6">
								Unplug is a mental health self-help platform for everyone.
								By providing users the ability to do mood journaling, we hope that people can monitor their own mental health.
							</p>
							<a role="button" className="btn bg-base-100 text-white" href="https://github.com/ngmhprogramming/unplug">
								<svg xmlns="http://www.w3.org/2000/svg" width={20} height={20} viewBox="0 0 24 24">
									<path fill="currentColor" d="M12 2A10 10 0 0 0 2 12c0 4.42 2.87 8.17 6.84 9.5c.5.08.66-.23.66-.5v-1.69c-2.77.6-3.36-1.34-3.36-1.34c-.46-1.16-1.11-1.47-1.11-1.47c-.91-.62.07-.6.07-.6c1 .07 1.53 1.03 1.53 1.03c.87 1.52 2.34 1.07 2.91.83c.09-.65.35-1.09.63-1.34c-2.22-.25-4.55-1.11-4.55-4.92c0-1.11.38-2 1.03-2.71c-.1-.25-.45-1.29.1-2.64c0 0 .84-.27 2.75 1.02c.79-.22 1.65-.33 2.5-.33s1.71.11 2.5.33c1.91-1.29 2.75-1.02 2.75-1.02c.55 1.35.2 2.39.1 2.64c.65.71 1.03 1.6 1.03 2.71c0 3.82-2.34 4.66-4.57 4.91c.36.31.69.92.69 1.85V21c0 .27.16.59.67.5C19.14 20.16 22 16.42 22 12A10 10 0 0 0 12 2"></path>
								</svg>
								GitHub
							</a>
						</div>
					</div>
				</div>
			</div>
		</div >
	)
}